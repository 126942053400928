import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  CeleryImportState,
  CeleryTaskStatus,
} from '../../models/drf-response.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageTypesUploadMemoService } from '../../../modules/manage-types-upload-memo/shared/manage-types-upload-memo.service';
import { buildErrorHTMLMessage } from '../../utils/common.util';
import { ApiService } from 'src/app/core/http/api.service';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-export-excel-modal',
  templateUrl: './export-excel-modal.component.html',
  styleUrls: ['./export-excel-modal.component.scss'],
})
export class ExportExcelModalComponent implements OnDestroy {
  @ViewChild('downloadModal', { static: true })
  downloadModal: ElementRef;
  @ViewChild('downloadEvidenceModal', { static: true })
  downloadEvidenceModal: ElementRef;

  @ViewChild('evidenceSuccessModal', { static: true })
  evidenceSuccessModal: ElementRef;
  @Input() checkPage: string;

  celeryImportState = CeleryImportState;

  sendingTaskInterval;
  sendingTaskDetail: CeleryTaskStatus;
  filePath: string;
  taskId: string;
  // "445309db-69b9-4c5e-95e5-ea75f4819a6c"
  // refresh less than 1-2 secs is too much. it should be more than 5 secs.
  @Input() timeout = 5000;
  @Input() resultTitle: string;
  @Input() isExportGoogle = false;
  @Input() startPercent = 0;
  @Output() isSuccess = new EventEmitter();
  @Input() evidenceDesign: boolean;

  subscription: Subscription[] = [];
  constructor(
    private apiService: ManageTypesUploadMemoService,
    private httpClientService: ApiService,
    public modalService: NgbModal,
    private idle: Idle,
    private router: Router,
  ) {}

  showModal(taskId: string): void {
    if (taskId == null) {
      console.error('taskId cannot be empty');
      return;
    }

    this.cancelSendingTask();
    this.filePath = '';
    this.taskId = taskId;
    this.subscribe(this.taskId);
    if (this.evidenceDesign) {
      this.modalService.open(this.downloadEvidenceModal, {
        backdrop: 'static',
        size: 'md',
        keyboard: false,
        centered: true,
      });
    } else {
      this.modalService.open(this.downloadModal, {
        backdrop: 'static',
        size: 'md',
        keyboard: false,
        centered: true,
      });
    }
  }

  subscribe(taskId: string): void {
    this.sendingTaskInterval = undefined;
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };
    this.subscribeSendingTaskStatus(taskId);
  }

  subscribeSendingTaskStatus(taskId: string): void {
    this.refreshTaskStatus(taskId);

    this.sendingTaskInterval = setInterval(() => {
      this.refreshTaskStatus(taskId);
    }, this.timeout);
  }

  refreshTaskStatus(taskId) {
    // send signal to Idle object that user is using website
    //   because report may take a long to time to process.
    this.idle.interrupt();

    if (
      this.sendingTaskDetail == null ||
      (this.sendingTaskDetail._state !== CeleryImportState.Success &&
        this.sendingTaskDetail._state !== CeleryImportState.Failure)
    ) {
      const subscription = this.apiService
        .getCeleryTaskStatus(taskId)
        .subscribe((res) => {
          // update progress
          if (
            this.startPercent > 0 &&
            (res._state === CeleryImportState.Pending ||
              res._state === CeleryImportState.Started)
          ) {
            this.sendingTaskDetail = {
              _state: CeleryImportState.Pending,
              percent: this.startPercent,
            };
          } else {
            this.sendingTaskDetail = res;
          }

          if (
            res._state === CeleryImportState.Success &&
            this.evidenceDesign
          ) {
            setTimeout(() => {
              this.close();
              this.openModal();
            }, 1000);
          }
          // unsubscribe when success or failure
          if (
            res._state === CeleryImportState.Success ||
            res._state === CeleryImportState.Failure
          ) {
            this.filePath = this.sendingTaskDetail.excel;
          }

          if (res._state === CeleryImportState.Failure) {
            res.detail = buildErrorHTMLMessage(res.detail);
          }
          if (res._state === CeleryImportState.Success) {
            if (this.isExportGoogle) {
              this.isSuccess.emit();
            }
          }
        });
      this.subscription.push(subscription);
    }
  }

  cancelSendingTask(): void {
    if (this.sendingTaskInterval) {
      clearInterval(this.sendingTaskInterval);
    }
  }

  close() {
    this.cancelSendingTask();
    this.modalService.dismissAll();
  }

  replaceUnderscore(text: string, withChar = ' '): string {
    return text.split('_').join(withChar);
  }

  downloadCSV() {
    const pathElement = this.filePath.split('/');
    const documentName =
      pathElement[pathElement.length - 1].split('?')[0];
    const subscription = this.httpClientService
      .download(this.filePath)
      .subscribe(
        (res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = documentName;
          link.click();
          this.modalService.dismissAll();
        },
        (error) => {
          console.log(error.status);
        },
      );
    this.subscription.push(subscription);
  }
  openModal(): void {
    this.modalService.open(this.evidenceSuccessModal, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  goBack(): void {
    this.router.navigate(['contract']).then();
    this.modalService.dismissAll();
  }
  ngOnDestroy(): void {
    this.cancelSendingTask();
    if (this.subscription) {
      this.subscription?.forEach((item) => {
        try {
          item.unsubscribe();
        } catch (_) {}
      });
    }
  }

  get resultKeys(): string[] {
    if (
      !this.sendingTaskDetail.rows ||
      !this.sendingTaskDetail.rows.length
    ) {
      return [];
    }

    return Object.keys(this.sendingTaskDetail.rows[0]);
  }

  get currentStateProgressType(): string {
    if (this.sendingTaskDetail._state === CeleryImportState.Success) {
      this.sendingTaskDetail.percent = 100;
      return 'success';
    }
    if (this.sendingTaskDetail._state === CeleryImportState.Failure) {
      return 'danger';
    }
    return 'info';
  }
}
